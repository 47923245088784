.c-about__grid {
  @media (min-width: $md) {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: var(--2vr);
  }

  @media (min-width: $lg) {
    grid-template-columns: 1fr 2fr;
  }
}

.c-about__image-wrapper {
  align-self: start;
  transform-origin: center center;
  position: relative;
  margin-bottom: 3rem;
  margin-left: 1rem;
  max-width: 15rem;

  @media (min-width: $md) {
    margin-bottom: 0;
    margin-left: 0;
    max-width: 25rem;
  }

  img {
    width: 100%;
    filter: grayscale(100%);
  }

  &::before {
    content: '';
    position: absolute;
    inset: 1rem 1rem -1rem -1rem;
    background: repeating-linear-gradient(
      45deg,
      var(--accent),
      var(--accent) 0.1rem,
      transparent 0,
      transparent 0.5rem
    );
    border: 0.1rem solid var(--accent);
    z-index: -1;
  }
}
