.c-social-icons {
  display: flex;
  flex-wrap: wrap;
  color: var(--accent);

  > * + * {
    margin-left: 1.5rem;
  }

  a {
    transition: color 200ms;
    color: var(--bg);

    &:hover,
    &:focus {
      color: deeppink;
    }
  }
}
