@font-face {
  font-family: 'Montserrat Black';
  src: url('../fonts/montserrat-black-webfont.woff2') format('woff2'),
    url('../fonts/montserrat-black-webfont.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/lato-regular-webfont.woff2') format('woff2'),
    url('../fonts/lato-regular-webfont.woff') format('woff');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: $md) {
    font-size: 1.2rem;
  }

  @media (min-width: $lg) {
    font-size: 1.4rem;
  }
}

h1,
h2,
h3,
h4 {
  font-family: 'Montserrat Black', sans-serif;
  font-weight: normal;
  line-height: 1.1;
  margin: 0;
}

h1,
.heading-1 {
  font-family: 'Montserrat Black', sans-serif;
  font-size: clamp(2rem, 10vw, 5rem);
  line-height: 1.1;
  letter-spacing: 0.01em;
  margin: 0;
}

h2 {
  line-height: 1.2;

  @media (min-width: $md) {
    font-size: 2.8rem;
  }
}

h3 {
  font-size: 1.1rem;

  @media (min-width: $md) {
    font-size: 2rem;
  }
}

p {
  line-height: 1.6;
  max-width: 65ch;
}

a {
  color: inherit;
}

figcaption {
  font-size: 1rem;
  margin-top: 1rem;

  @media (min-width: $md) {
    font-size: 1.2rem;
  }
}

li::marker {
  color: var(--accent);
}
