body {
  background-color: var(--bg);
  color: var(--text);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;

  @media (min-width: $lg) {
    &.is-loading {
      header,
      main {
        opacity: 0;
      }
    }
  }
}

.is-loaded-complete {
  header,
  main {
    opacity: 1;
    transition: opacity 100ms;
  }
}

* {
  scroll-behavior: smooth;

  @supports (prefers-reduced-motion: reduce) {
    scroll-behavior: auto;
  }
}

.container {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding: 0 1rem;

  @media (min-width: $md) {
    padding: 0 2rem;
  }
}

.page-content {
  position: relative;
  z-index: 1;
  background: white;

  @media (min-width: $lg) {
    --clip: polygon(100% 0, 100% 100%, 0 100%, 0 7vmin);

    scroll-margin-top: -1rem;
    -webkit-clip-path: var(--clip);
    clip-path: var(--clip);
    margin-top: 100vh;
    padding: 6rem 0 0 0;
  }
}

.is-loading {
  header,
  main {
    opacity: 0;
    transition: opacity 1000ms;
  }
}
