:root {
  --vr: 1rem;
  --2vr: calc(var(--vr) * 2);
  --text: #f7f7f7;
  --bg: #090e17;
  --accent: turquoise;
}

@media (width >= 650px) {
  :root {
    --vr: 2rem;
  }
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: block;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

svg {
  fill: currentColor;
  width: 100%;
  height: 100%;
}

figure {
  margin: 0;
}

ul:not([class]) {
  padding-left: var(--vr);
  margin: 0;
}

:focus, button:focus {
  outline: none;
}

:focus-visible, button:focus-visible {
  outline: 2px dashed var(--accent);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

@font-face {
  font-family: Montserrat Black;
  src: url("montserrat-black-webfont.85d13ed2.woff2") format("woff2"), url("montserrat-black-webfont.f4062171.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Lato;
  src: url("lato-regular-webfont.ab12bb31.woff2") format("woff2"), url("lato-regular-webfont.9391f48a.woff") format("woff");
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Lato, sans-serif;
}

@media (width >= 650px) {
  body {
    font-size: 1.2rem;
  }
}

@media (width >= 1024px) {
  body {
    font-size: 1.4rem;
  }
}

h1, h2, h3, h4 {
  margin: 0;
  font-family: Montserrat Black, sans-serif;
  font-weight: normal;
  line-height: 1.1;
}

h1, .heading-1 {
  letter-spacing: .01em;
  margin: 0;
  font-family: Montserrat Black, sans-serif;
  font-size: clamp(2rem, 10vw, 5rem);
  line-height: 1.1;
}

h2 {
  line-height: 1.2;
}

@media (width >= 650px) {
  h2 {
    font-size: 2.8rem;
  }
}

h3 {
  font-size: 1.1rem;
}

@media (width >= 650px) {
  h3 {
    font-size: 2rem;
  }
}

p {
  max-width: 65ch;
  line-height: 1.6;
}

a {
  color: inherit;
}

figcaption {
  margin-top: 1rem;
  font-size: 1rem;
}

@media (width >= 650px) {
  figcaption {
    font-size: 1.2rem;
  }
}

li::marker {
  color: var(--accent);
}

body {
  background-color: var(--bg);
  color: var(--text);
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  display: flex;
}

@media (width >= 1024px) {
  body.is-loading header, body.is-loading main {
    opacity: 0;
  }
}

.is-loaded-complete header, .is-loaded-complete main {
  opacity: 1;
  transition: opacity .1s;
}

* {
  scroll-behavior: smooth;
}

@supports (prefers-reduced-motion: reduce) {
  * {
    scroll-behavior: auto;
  }
}

.container {
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (width >= 650px) {
  .container {
    padding: 0 2rem;
  }
}

.page-content {
  z-index: 1;
  background: #fff;
  position: relative;
}

@media (width >= 1024px) {
  .page-content {
    --clip: polygon(100% 0, 100% 100%, 0 100%, 0 7vmin);
    -webkit-clip-path: var(--clip);
    clip-path: var(--clip);
    margin-top: 100vh;
    padding: 6rem 0 0;
    scroll-margin-top: -1rem;
  }
}

.is-loading header, .is-loading main {
  opacity: 0;
  transition: opacity 1s;
}

@property --offset {
  syntax: "<length>";
  inherits: false;
  initial-value: 3px;
}

.o-richtext * + * {
  margin-top: var(--vr);
}

.o-richtext * + h2, .o-richtext * + h3 {
  margin-top: calc(2 * var(--vr));
}

.o-richtext ul {
  margin-left: 2rem;
  list-style-type: disc;
}

.o-richtext li + li {
  margin-top: calc(var(--vr) / 2);
}

.o-richtext h2 + h3 {
  margin-top: var(--vr);
}

.o-richtext pre {
  max-width: 40rem;
  padding: 1rem;
  display: block;
}

.o-richtext code, .o-richtext pre {
  background-color: #e8e8e9;
  border-radius: .25rem;
}

.o-richtext h1 {
  margin-bottom: calc(2 * var(--vr));
  padding-bottom: var(--vr);
  border-bottom: .2rem solid;
}

.o-richtext a {
  text-underline-offset: var(--offset, .25em);
  -webkit-text-decoration-color: var(--accent);
  text-decoration-color: var(--accent);
  font-weight: 700;
  text-decoration-thickness: .1em;
  transition: --offset .2s, color .2s;
}

.o-richtext a:hover, .o-richtext a:focus {
  --offset: .55em;
  color: #ff1493;
}

@supports not (background: paint(something)) {
  .o-richtext a {
    transition: text-underline-offset .2s, color .2s;
  }

  .o-richtext a:hover, .o-richtext a:focus {
    text-underline-offset: .55em;
  }
}

.c-header {
  z-index: 2;
  width: 100%;
  padding: 1rem 1rem .5rem;
  position: fixed;
  top: 0;
  left: 0;
}

@media (width >= 650px) {
  .c-header {
    align-items: center;
    min-height: 4.5rem;
    padding: .5rem 1rem .5rem 2rem;
    display: flex;
  }
}

.c-header__menu-btn {
  appearance: none;
  z-index: 2;
  cursor: pointer;
  width: rem(40px);
  height: rem(40px);
  color: var(--text);
  background-color: #0000;
  background-color: var(--bg);
  border: none;
  border-radius: 50%;
  margin-left: auto;
  padding: .75rem;
  font-family: inherit;
  font-size: 1rem;
  text-decoration: underline;
  transition: color .2s, background-color .2s;
  position: absolute;
  top: .5rem;
  right: .5rem;
}

.c-header__menu-btn:hover, .c-header__menu-btn:focus-visible {
  color: #ff1493;
}

@media (width >= 650px) {
  .c-header__menu-btn {
    font-size: 1.2rem;
    right: 1.5rem;
  }
}

.c-header__menu-icon {
  width: 1.6rem;
  height: 1.6rem;
  display: block;
  position: relative;
  top: 2px;
}

.c-header__menu-icon > span, .c-header__menu-icon:before, .c-header__menu-icon:after {
  transform-origin: center;
  pointer-events: none;
  background-color: currentColor;
  width: 100%;
  height: 4px;
  transition: opacity 95ms;
  position: absolute;
  top: calc(50% - 4px);
  left: 0;
}

.c-header__menu-icon:before, .c-header__menu-icon:after {
  content: "";
  transition: transform 95ms cubic-bezier(.55, .055, .675, .19);
}

.c-header__menu-icon:before {
  transform: translate3d(0, -10px, 0)rotate(0);
}

.c-header__menu-icon:after {
  transform: translate3d(0, 10px, 0)rotate(0);
}

.c-header__nav {
  margin-left: auto;
}

.c-header__list {
  flex-wrap: wrap;
  margin-left: -1rem;
  display: flex;
}

@media (width >= 1024px) {
  .c-header__list {
    flex-wrap: nowrap;
    justify-content: flex-end;
  }
}

.c-header__link {
  color: var(--text);
  text-underline-offset: .2em;
  align-items: center;
  height: 100%;
  padding: .5rem 1rem;
  -webkit-text-decoration: underline #0000;
  text-decoration: underline #0000;
  text-decoration-thickness: .1em;
  transition: text-decoration-color .3s ease-out;
  display: flex;
}

.c-header__link svg {
  width: 1.75rem;
  height: 1.75rem;
  margin-left: .5rem;
}

.c-header__link:hover, .c-header__link:focus {
  -webkit-text-decoration-color: var(--text);
  text-decoration-color: var(--text);
}

@media (width >= 650px) {
  .c-header__link {
    font-size: 1rem;
  }
}

@media (width >= 1024px) {
  .c-header__link {
    font-size: 1.1rem;
  }
}

.c-header__title {
  margin-bottom: .5rem;
  font-family: Amiri, serif;
  font-size: 1.4rem;
  line-height: 1;
}

.c-header__title a {
  color: var(--accent);
  text-decoration: none;
  display: block;
}

.c-header__title a:hover, .c-header__title a:focus {
  color: var(--text);
}

@media (width >= 650px) {
  .c-header__title {
    margin-bottom: 0;
    padding: .5rem 0;
    font-size: 2rem;
  }
}

.js-menu .c-header__nav {
  clip-path: circle(0% at calc(100% - 2rem) 2rem);
  pointer-events: none;
  background: linear-gradient(45deg, #9932cc, #ff1493, orange);
  width: 100%;
  height: 100vh;
  padding: 5rem 1rem 2rem;
  font-family: Montserrat Black, sans-serif;
  font-weight: 900;
  transition: opacity .2s, clip-path .5s;
  position: absolute;
  top: 0;
  right: 0;
  overflow-y: scroll;
}

@media (width >= 650px) {
  .js-menu .c-header__nav {
    padding: 5rem 2rem 2rem;
  }
}

.js-menu .c-header__list {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  margin-left: 0;
  display: flex;
}

.js-menu .c-header__list > * + * {
  margin-top: var(--vr);
}

.js-menu .c-header__link {
  font-size: min(5vw, 3rem);
}

.is-visible .c-header__nav {
  opacity: 1;
  clip-path: circle(200% at calc(100% - 2rem) 2rem);
  pointer-events: all;
}

.is-menu-visible .c-header__menu-icon span {
  opacity: 0;
}

.is-menu-visible .c-header__menu-icon:before {
  transform: translate3d(0, 0, 0)rotate(45deg);
}

.is-menu-visible .c-header__menu-icon:after {
  transform: translate3d(0, 0, 0)rotate(-45deg);
}

.c-footer {
  color: #cdced1;
  background-color: #020305;
  padding: 1rem;
}

@media (width >= 650px) {
  .c-footer {
    padding: 1rem 2rem;
  }
}

@media (width >= 1024px) {
  .c-hero {
    width: 100%;
    position: fixed;
  }
}

.c-hero__content {
  position: relative;
}

.c-hero__wrapper {
  background: var(--bg);
  color: var(--accent);
  align-items: center;
  min-height: 100vh;
  padding: 4rem 0 5rem;
  display: flex;
}

@media (width >= 650px) {
  .c-hero__wrapper {
    padding: 10rem 0;
    font-size: 1.6rem;
  }
}

.c-hero__heading {
  color: var(--accent);
  filter: drop-shadow(0 0 .35rem var(--accent));
}

.c-hero__heading .char {
  opacity: 0;
  display: inline-block;
  transform: translate3d(0, .5em, 0);
}

@supports (-webkit-text-stroke-width: 1px) {
  .c-hero__heading {
    color: #0000;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--accent);
  }
}

@media (width >= 1024px) {
  .c-hero__heading {
    font-size: 6rem;
  }
}

.c-hero__wrapper--main .c-hero__desc {
  opacity: 0;
  transform: translate3d(0, 1rem, 0);
}

.c-hero__wrapper--main .c-hero__desc.is-visible {
  opacity: 1;
  transition: opacity .7s cubic-bezier(.24, .02, .32, 1), transform .7s cubic-bezier(.24, .02, .32, 1);
  transform: translate3d(0, 0, 0);
}

.c-hero__link-wrapper {
  justify-content: center;
  max-width: 20rem;
  margin: 0 auto;
  display: flex;
}

@media (width >= 650px) {
  .c-hero__link-wrapper {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}

.is-complete .c-hero__heading .char {
  --delay: calc(var(--char-index) * .15s);
  animation: appear .8s var(--delay, 0s) forwards, slide .9s var(--delay, 0s) forwards;
}

@keyframes appear {
  100% {
    opacity: 1;
  }
}

@keyframes slide {
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.c-hero__wrapper--cover {
  --circleX: var(--cursorX, 50%);
  --circleY: var(--cursorY, 50%);
  --grad: circle at var(--circleX, 50%) var(--circleY, 50%), black var(--maskSize, 0%), transparent 0, transparent var(--maskSize2, 0%), black var(--maskSize2, 0%), black var(--maskSize3, 0%), transparent 0;
  pointer-events: none;
  background: linear-gradient(45deg, turquoise, darkorchid, deeppink, orange), var(--text);
  color: var(--text);
  -webkit-mask-image: radial-gradient(var(--grad));
  -webkit-mask-image: radial-gradient(var(--grad));
  mask-image: radial-gradient(var(--grad));
  position: absolute;
  inset: 0;
}

@media (prefers-reduced-motion: reduce) {
  .c-hero__wrapper--cover {
    --circleX: 50%;
    --circleY: 50%;
  }
}

.c-hero__wrapper--cover .c-hero__desc {
  opacity: 1;
}

.c-hero__wrapper--cover .c-hero__heading {
  color: var(--text);
  filter: none;
}

@supports (-webkit-text-stroke-width: 1px) {
  .c-hero__wrapper--cover .c-hero__heading {
    -webkit-text-stroke-width: 0;
  }
}

@media (width >= 650px) {
  .c-hero__wrapper--cover .c-hero__heading {
    background: radial-gradient(circle at center, var(--text) .11rem, transparent 0);
    color: #0000;
    background-size: .4rem .4rem;
    -webkit-background-clip: text;
    background-clip: text;
  }
}

.no-js .char {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.no-js .c-hero__desc {
  opacity: 1;
  transition: none;
}

.section {
  z-index: 1;
  color: var(--bg);
  scroll-margin-top: 6rem;
  position: relative;
  overflow: hidden;
}

@media (width >= 1024px) {
  .section {
    scroll-margin-top: 12rem;
  }
}

.section:nth-child(2n), .section--contact {
  --accent: var(--bg);
  background: #defcfc;
}

.c-divider {
  height: 0;
  position: relative;
  overflow: visible;
}

@media (width >= 1024px) {
  .c-divider:after {
    content: "";
    clip-path: polygon(0 0, 100% 50%, 0 100%);
    z-index: 2;
    background: linear-gradient(to right, #9932cc, #ff1493, orange);
    width: 100%;
    height: 12rem;
    position: absolute;
    bottom: -6rem;
    left: 0;
  }
}

.c-section__content {
  min-height: 100%;
  padding: 2rem 0;
}

@media (width >= 650px) {
  .c-section__content {
    padding: 6rem 0;
  }
}

@media (width >= 1024px) {
  .c-section__content {
    padding: 10rem 0;
  }
}

.c-section__heading {
  margin-bottom: var(--2vr);
  display: inline-block;
  position: relative;
}

@media (width >= 650px) and (prefers-reduced-motion: no-preference) {
  .c-section__heading .char {
    --d: calc(var(--char-index) * .1s);
    opacity: 0;
    transition: opacity .55s var(--d), transform .55s var(--d);
    display: inline-block;
    transform: translate3d(0, .5em, 0);
  }
}

.c-section__heading svg {
  stroke: #ff1493;
  stroke-dasharray: 1;
  stroke-dashoffset: 1px;
  width: auto;
  height: .5rem;
  transition: stroke-dashoffset 2s .5s;
  position: absolute;
  bottom: -.75em;
  left: 0;
}

@media (width >= 650px) {
  .c-section__heading svg {
    height: 1rem;
  }
}

.c-section__inner-content {
  z-index: 1;
  position: relative;
}

@media (width >= 650px) and (prefers-reduced-motion: no-preference) {
  .c-section__inner-content {
    opacity: 0;
    transition: opacity .5s, transform .5s;
    transform: translate3d(0, .5em, 0);
  }
}

.c-section__social-list {
  margin: var(--vr) 0;
}

.should-show-text {
  --easeCB: cubic-bezier(.4, .07, .28, .96);
}

@media (width >= 650px) {
  .should-show-text .c-section__heading .char {
    --d: calc(var(--char-index) * 75ms);
    opacity: 1;
    transition: opacity .4s var(--d), transform .4s var(--d);
    transform: translate3d(0, 0, 0);
  }
}

.should-show-text .c-section__heading svg {
  stroke-dashoffset: 0;
}

@media (width >= 650px) and (prefers-reduced-motion: no-preference) {
  .should-show-text .c-section__inner-content {
    opacity: 1;
    transition: opacity 1s .7s var(--easeCB), transform 1s .7s var(--easeCB);
    transform: translate3d(0, 0, 0);
  }
}

.no-js .section {
  min-height: auto;
}

@media (width >= 1024px) {
  .no-js .section {
    min-height: 100vh;
  }
}

@media (width >= 650px) and (prefers-reduced-motion: no-preference) {
  .no-js .c-section__heading .char, .no-js .c-section__inner-content {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@media (width >= 650px) {
  .c-about__grid {
    gap: var(--2vr);
    grid-template-columns: 1fr 3fr;
    display: grid;
  }
}

@media (width >= 1024px) {
  .c-about__grid {
    grid-template-columns: 1fr 2fr;
  }
}

.c-about__image-wrapper {
  transform-origin: center;
  align-self: start;
  max-width: 15rem;
  margin-bottom: 3rem;
  margin-left: 1rem;
  position: relative;
}

@media (width >= 650px) {
  .c-about__image-wrapper {
    max-width: 25rem;
    margin-bottom: 0;
    margin-left: 0;
  }
}

.c-about__image-wrapper img {
  filter: grayscale();
  width: 100%;
}

.c-about__image-wrapper:before {
  content: "";
  background: repeating-linear-gradient(45deg, var(--accent), var(--accent) .1rem, transparent 0, transparent .5rem);
  border: .1rem solid var(--accent);
  z-index: -1;
  position: absolute;
  inset: 1rem 1rem -1rem -1rem;
}

.c-social-icons {
  color: var(--accent);
  flex-wrap: wrap;
  display: flex;
}

.c-social-icons > * + * {
  margin-left: 1.5rem;
}

.c-social-icons a {
  color: var(--bg);
  transition: color .2s;
}

.c-social-icons a:hover, .c-social-icons a:focus {
  color: #ff1493;
}

.hidden {
  display: none;
}

/*# sourceMappingURL=styles.css.map */
