@property --offset {
  syntax: '<length>';
  inherits: false;
  initial-value: 3px;
}

.o-richtext {
  * + * {
    margin-top: var(--vr);
  }

  * + h2,
  * + h3 {
    margin-top: calc(2 * var(--vr));
  }

  ul {
    list-style-type: disc;
    margin-left: 2rem;
  }

  li + li {
    margin-top: calc(var(--vr) / 2);
  }

  h2 + h3 {
    margin-top: var(--vr);
  }

  pre {
    display: block;
    padding: 1rem;
    max-width: 40rem;
  }

  code,
  pre {
    background-color: lighten($lightGrey, 10%);
    border-radius: 0.25rem;
  }

  h1 {
    margin-bottom: calc(2 * var(--vr));
    padding-bottom: var(--vr);
    border-bottom: 0.2rem solid;
  }

  a {
    text-underline-offset: var(--offset, 0.25em);
    text-decoration-color: var(--accent);
    text-decoration-thickness: 0.1em;
    font-weight: 700;
    transition: --offset 200ms, color 200ms;

    &:hover,
    &:focus {
      --offset: 0.55em;
      color: deeppink;
    }

    @supports not (background: paint(something)) {
      transition: text-underline-offset 200ms, color 200ms;

      &:hover,
      &:focus {
        text-underline-offset: 0.55em;
      }
    }
  }
}
